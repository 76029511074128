import React from "react"

import "./project.css"
import "../../node_modules/react-image-gallery/styles/css/image-gallery.css"
import ProjectPage from "../components/projectpage";

const IndexPage = ({ data }) => {
    return (<div className="background">
        <ProjectPage>
            <h1>JUMP into STEM</h1>
            <h4>September 2020 - December 2020</h4>
            <p>
                In this competition, we were tasked to create a solution to help create grid interactive efficient buildings. We chose to focus on an extremely high source of energy inefficiency, water heaters. While many HVAC systems are shifting to be smart and save energy, water heaters are still extremely old and inefficient to both in energy but also significantly raise the peak in a grid, causing energy to be obtained from places worse for the environment like peaker plants. We proposed the solution of creating a grid of smart water heaters that could communicate between each other and allow them to heat water at the best times for user and the grid. In doing this, we created an algorithm to lower the peak energy used and save money for the user. We were actually featured in the Georgia Tech Daily Digest <a rel="noopener noreferrer"  href="https://daily.gatech.edu/digest/2020/12/28" target="_blank">here</a> and had an article written on us <a rel="noopener noreferrer"  href="https://iac.gatech.edu/news/item/642162/georgia-tech-students-jump-into-stem-third-consecutive-year?utm_medium=email&utm_source=daily-digest&utm_campaign=2020-12-28&utm_content=news" target="_blank">here</a>.

            </p>
        </ProjectPage>
    </div>)
}

export default IndexPage
