import "./main.css"
import React, { Component } from 'react'
import {
  Icon, Container, Grid
} from "semantic-ui-react"
import Link from "gatsby-link"

export default class ProjectPage extends Component {
  render() {
    return (<div>
      <Container style={{paddingTop:"20px"}}>
        <Grid divided='vertically'>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Link to="/"><Icon name="home" style={{ color: "#5E7CE2" }} size="huge"></Icon></Link>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <a rel="noopener noreferrer" target="_blank" href="https://www.github.com/LucasKiefer" ><Icon color="black" name="github" size='huge' link /></a>
              <a rel="noopener noreferrer" href="https://www.linkedin.com/in/lucaskiefer/" target="_blank"><Icon name="linkedin" size='huge' /></a>
              <a rel="noopener noreferrer" href="mailto:keekskiefer@gmail.com" target="_blank"><Icon color="black" link name="mail" size='huge' /></a>      </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <Container style={{ marginTop: "30px" }}>
        {this.props.children}
      </Container>

    </div>
    )
  }
}